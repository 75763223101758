import React, { useEffect, useState } from 'react';
import Breadcrumbs from "../../Common/Breadcrumps/Breadcrumps";
import IconInStock from '../../../image/have_in_stock.png';
import IconPreOrder from '../../../image/cart_in_stock.png';
import ImageProductMain from '../../../image/test_product_card.png';
import TagsContainer from "../../Common/Tags/TagsContainer";
import ImageSlider from "ac-react-simple-image-slider";
import {decode} from 'html-entities';
import MetaTags from 'react-meta-tags';
import {formatSum} from "../../../store/reducers/MainReducer";
import NotFound404 from '../../Common/404/404Container';
import ProductsGalleryContainer from "../Gallery/ProductsGalleryContainer";
import ApiService from "../../../api/api";
import ShemaProductPage from "../../Common/Shema/ShemaProductPage";

const apiService = new ApiService();

const ProductsDetail = (props) => {

    const [isLoaded, changeLoaded] = useState(false);
    const renderRates = (rates_number) => {
        let rates = [];
        for(let i = 1; i <= rates_number; i+= 0.5) {
            i % 1 === 0 && rates.push(<i className="fas fa-star"> </i>);
            if(i === rates_number && i % 1 !== 0) {
                rates.push(<i className="fas fa-star-half"> </i>)
            }
        }
        return rates;
    }

    const getSliderImages = (images) => {
        const main = images.filter((e) => e.isMain).map(e => {
            return {src: e.link};
        })[0];
        return [
            main ? main : '',
            ...images.filter((e) => !e.isMain).map(e => {
                return {src: e.link};
            })
        ];
    }

    const createMarkup = (html) => {
        return {
        __html: decode(html)
        };
    }

    const getManufacturerCountry = (option) => {
        return option && option.filter((e) => Number(e.id) === 28) && option.filter((e) => Number(e.id) === 28)[0] ? option.filter((e) => Number(e.id) === 28)[0].value : "";
    }

    useEffect(() => {
        if(props.product.id > 0) {
            changeLoaded(true);
            window.dataLayer = window.dataLayer || [];
            try {
                window.dataLayer.push({ ecommerce: null });
                window.dataLayer.push({
                    event: 'view_item',
                    ecommerce: {
                        items: [
                            {
                                item_name: props.product.name, // Назва товару
                                item_id: props.product.id, // Унікальний ідентифікатор
                                price: props.product.price, // Ціна
                                currency: "UAH", // Вказати валюту
                                item_category: props.product.analyte, // Категорія
                                quantity: 1, // Кількість (для перегляду завжди 1)
                            },
                        ],
                    },
                });
            } catch(error) {
                console.log(error);
            }
        }
    }, [props.product.id])

    return isLoaded ? (
        <div className={'products_card_main_container'}>
            <ShemaProductPage product={props.product}/>
            {props.product && props.product.seo && <>
                <MetaTags>
                    <title>{props.product.seo.title}</title>
                    <meta name="description" content={props.product.seo.description} />
                    <meta name="keywords" content={props.product.seo.keywords} />
                </MetaTags>
            </>}
            <Breadcrumbs name={props.product.name}
                         pages={props.category ? [
                             props.category.parent_data ? {name: props.category.parent_data.name, link: '/category/' + props.category.parent_data.alias} : {},
                             {name: props.category.name, link: '/category/' + props.category.alias}
                         ] : []
                         }
            />
            <div className="row">
                <div className="products_card_info_container">
                    <div className="col-md-12 products_card_info_header">
                        <div className="products_card_main_title">
                            <h1>{props.product.name}</h1>
                            {props.product && props.product.long_name && props.product.long_name.length > 0 ?
                            <>
                                <p className={"product_card_long_name"}>{props.product.long_name}</p>
                            </>
                                : ""
                            }
                        </div>
                        <div className="products_card_info_sku">
                            {props.getTranslate('front_product_cart','product_cart_model')}: {props.product.model}
                            <span>{props.getTranslate('front_product_cart','product_cart_manufacturer')}: {props.product.manufacturer_name} ({getManufacturerCountry(props.product.options)})</span>
                        </div>
                    </div>
                    {Number(props.product.rates) ? <>
                        <div className="col-md-12 products_card_rates_container">
                            {renderRates(props.product.rates)}
                            <span>
                            {props.product.rates}/5
                        </span>
                        </div>
                    </> : ''}
                    <div className="products_card_general_info">
                        <div className="row">
                            <div className="col-md-5">
                                <div className="products_card_image_container">
                                    <ImageSlider height='504px'
                                                 width='100%'
                                                 autoPlay={false}
                                                 showArrows={props.product.images && props.product.images[1] ? true : false}
                                                 showDots={false}
                                                 duration={5}
                                                 data={getSliderImages(props.product.images ? props.product.images : [])}
                                    />
                                    {props.product.discount_percent && props.product.discount_percent > 0 ?
                                        <div className={"product_gallery_discount_percent_container"}>
                                            {/*<span>-{String((1 - Number(props.product.discount_percent).toFixed(2))*100).substring(0,2)}%</span>*/}
                                            <span>-{Math.floor((1 - Number(props.product.discount_percent).toFixed(2))*100) ? Math.floor((1 - Number(props.product.discount_percent).toFixed(2))*100) : 1}%</span>
                                        </div>
                                        : ""
                                    }
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="products_card_datas_container">
                                    <div className="products_card_options_container">
                                        <div className="products_card_options_title">
                                            <span>{props.getTranslate('front_product_detail','product_detail_characteristic')}</span>
                                        </div>
                                        <div className="products_card_in_stock">
                                            {props.product.count && Number(props.product.count) > 0 ?
                                                <>
                                                    <img src={IconInStock} alt=""/>
                                                    <span>{props.getTranslate('front_product_detail','product_detail_in_stock')}</span>
                                                </>
                                                :
                                                <>
                                                    <img src={IconPreOrder} alt=""/>
                                                    <span>{props.getTranslate('front_product_detail','product_detail_preorder')}</span>
                                                </>
                                            }
                                        </div>
                                        <div className="products_cart_options_items">
                                            {props.product && props.product.analyte ?
                                            <div className="products_cart_options_item">
                                                <div className="products_cart_options_item_title">
                                                    {props.getTranslate('front_product_cart','product_cart_analyte')}:
                                                </div>
                                                <div className="products_cart_options_item_value">
                                                    {props.product.analyte}
                                                </div>
                                            </div>
                                                : ""}
                                            <div className="products_cart_options_item">
                                                <div className="products_cart_options_item_title">
                                                    {props.getTranslate('front_product_cart','product_cart_in_pack')}:
                                                </div>
                                                <div className="products_cart_options_item_value">
                                                    {props.product.in_pack}
                                                </div>
                                            </div>
                                            {props.product.options && props.product.options
                                                .filter(e => Number(e.id) !== 28)
                                                .map(e => {
                                                return <div className="products_cart_options_item">
                                                    <div className="products_cart_options_item_title">
                                                        {e.name}:
                                                    </div>
                                                    <div className="products_cart_options_item_value">
                                                        {e.value}
                                                    </div>
                                                </div>
                                            }) }
                                        </div>
                                    </div>
                                    <div className="products_card_price_container">
                                        <div className="products_card_price_inner">
                                            <div className="products_card_price_all">
                                                {/*₴ {formatSum(props.product.price)}*/}
                                                {props.product.old_price && props.product.old_price > 0 ?
                                                <div className={"products_cart_price_discount"}>
                                                        <div className={"product_gallery_discount_price_container"}>
                                                            <div className={"product_gallery_price_discount_old_price"}>₴ {formatSum(props.product.old_price)}</div>
                                                            <div className={"product_gallery_price_discount"}>₴ {formatSum(props.product.price)}</div>

                                                        </div>
                                                </div> : <div>₴ {formatSum(props.product.price)}</div>}
                                            </div>
                                            {props.product.price != props.product.price_one ?
                                                <div className="products_card_price_for_one">
                                            {props.getTranslate('front_product_cart','product_cart_price_one')}: ₴ {Number(formatSum(props.product.price_one)).toFixed(2)}
                                                </div> : ''
                                            }
                                        </div>
                                        <div className="products_card_buttons_container">
                                            <button className="btn" onClick={() => props.addToCart(props.product)}>{props.getTranslate('front_product_cart','product_cart_add_in_card')}</button>
                                            {/*<button className="btn btn_inverse">Купити в 1 клік</button>*/}
                                        </div>
                                    </div>
                                    {props.product.count && Number(props.product.count) > 0 ? "" :<p className={'product_detail_info_price_change'}>{props.getTranslate('front_calculate','calculate_info_price_change')}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="products_card_tabs_container">
                    <ul className="nav nav-tabs">
                        <li className="nav-item">
                            <a className={'nav-link ' + (props.activeTab === 'description' ? 'active' : '')}
                               onClick={() =>props.setActiveTab('description')}>
                                {props.getTranslate('front_product_detail','product_detail_description')}
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className={'nav-link ' + (props.activeTab === 'documents' ? 'active' : '')}
                               onClick={() =>props.setActiveTab('documents')}>
                                {props.getTranslate('front_product_detail','product_detail_documents')}
                            </a>
                        </li>
                    </ul>
                    <div className="tab-content">
                        <div className={'tab-pane fade ' + (props.activeTab === 'description' ? ' show active' : '')}>
                            {props.product.description && <div dangerouslySetInnerHTML={createMarkup(props.product.description)} />}
                        </div>
                        <div className={'tab-pane fade ' + (props.activeTab === 'documents' ? ' show active' : '')}>
                            <div className={"documents_for_product_list"}>
                                {props.product.documents ? props.product.documents.map(e => {
                                    return <a href={e.link}>
                                        <i className="fas fa-file-pdf text-danger pdf_link_icon"></i>
                                        {e.name}
                                    </a>
                                }) : <h4>{props.getTranslate('front_product_detail','product_detail_documents_not_found')}</h4>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<TagsContainer tags={props.product.tags}/>*/}
            {props.product && props.product.recommend_products && <ProductsGalleryContainer title={props.getTranslate('front_product_category', 'product_category_title_equivalent')} count={4} products={apiService._transformProducts(props.product.recommend_products)} type={'equivalent'} view={'grid'}/>}
            {props.product && props.product.tags && props.product.tags[0] && props.product.tags[0].id && Number(props.product.tags[0].id) > 0 ? <TagsContainer tags={props.product.tags}/> : " "}
        </div>
    ) : <NotFound404/>
};

export default ProductsDetail;
